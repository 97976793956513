var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container pt-3 mt-3"},[_c('h4',[_vm._v("Unificação de cadastros")]),_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.buscar.apply(null, arguments)}}},[_c('div',{staticClass:"row mt-4"},[_c('div',{staticClass:"col-4"}),_c('div',{staticClass:"col-4 text-left"},[_c('b-form-group',{staticClass:"w-100",attrs:{"label":"Buscar Nome / CPF"}},[_c('b-input',{attrs:{"type":"text","placeholder":"digite um nome ou cpf"},model:{value:(_vm.filtro),callback:function ($$v) {_vm.filtro=$$v},expression:"filtro"}})],1)],1),_c('div',{staticClass:"col-4 text-left pt-4"},[_c('b-btn',{staticClass:"mt-2",attrs:{"variant":"primary","type":"submit"}},[_c('b-icon-search'),_vm._v(" Buscar")],1)],1)])]),(_vm.loading)?_c('div',{staticClass:"w-100 p-5 text-center"},[_c('b-spinner'),_c('br'),_vm._v(" aguarde... ")],1):_vm._e(),(!_vm.loading)?_c('div',{staticClass:"row"},[_c('div',{class:{
        'col-6': _vm.selecionados.length > 0,
        'col-12': _vm.selecionados.length <= 0,
      }},[_vm._m(0),_c('b-table',{ref:"selectableTable",attrs:{"hover":"","striped":"","small":"","id":"selectableTable","items":_vm.resultados,"fields":[
          { key: 'select', label: '' },
          { key: 'id', label: 'Código' },
          { key: 'razao_social', label: 'Nome' },
          { key: 'cpf', label: 'CPF' },
          { key: 'tel', label: 'Telefone' },
        ],"selectable":""},on:{"row-selected":_vm.onRowSelected},scopedSlots:_vm._u([{key:"cell(razao_social)",fn:function(row){return [_c('div',{staticClass:"text-left"},[_vm._v(" "+_vm._s(row.item.razao_social)+" ")])]}},{key:"cell(select)",fn:function(row){return [_c('b-checkbox',{attrs:{"value":row.item},model:{value:(_vm.selecionados),callback:function ($$v) {_vm.selecionados=$$v},expression:"selecionados"}})]}},{key:"cell(tel)",fn:function(row){return [_c('div',{staticClass:"text-center"},[(row.item.telefones && row.item.telefones.length > 0)?_c('span',[_vm._v(" "+_vm._s(row.item.telefones[0].telefone)+" ")]):_vm._e()])]}}],null,false,2493252180)})],1),(_vm.selecionados.length > 0)?_c('div',{staticClass:"col-6"},[_c('h5',[_vm._v("Selecionados")]),_c('hr'),_c('div',{staticClass:"m-4 card"},[_c('div',{staticClass:"card-body"},[_c('h5',[_vm._v("Paciente Principal")]),_c('div',{staticClass:"text-left"},[_c('b',[_vm._v("Nome: ")]),_vm._v(" "+_vm._s(_vm.pacientePrincipal.razao_social)),_c('br'),_c('b',[_vm._v("CPF: ")]),_vm._v(" "+_vm._s(_vm.pacientePrincipal.cpf)),_c('br'),_c('b',[_vm._v("Telefone(s): ")]),_c('ul',_vm._l((_vm.pacientePrincipal.telefones),function(tel){return _c('li',{key:`telef_pr_${tel.telefone}_${Math.random(2323232)}`},[_vm._v(" "+_vm._s(tel.telefone)+" ")])}),0),_c('br')])])]),_c('b',[_vm._v("Unificando os seguintes registros")]),_c('b-table',{attrs:{"items":_vm.selecionados,"small":"","striped":"","fields":[
          { key: 'select', label: '' },
          { key: 'id', label: 'Código' },
          { key: 'razao_social', label: 'Nome' },
          { key: 'cpf', label: 'CPF' },
          { key: 'tel', label: 'Telefone' },
        ]}}),_c('b-btn',{attrs:{"block":"","variant":"success","disabled":_vm.unificando},on:{"click":_vm.unificar}},[(_vm.unificando)?_c('b-spinner',{attrs:{"small":""}}):_vm._e(),(!_vm.unificando)?_c('b-icon-journal-arrow-down'):_vm._e(),_vm._v(" Unificar ")],1)],1):_vm._e()]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('small',[_c('i',[_vm._v(" *O primeiro registro selecionado será automaticamente o cadastro de origem para unificação."),_c('br'),_vm._v(" *Esta ação não pode ser desfeita ")])])
}]

export { render, staticRenderFns }