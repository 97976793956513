<template>
  <div class="container pt-3 mt-3">
    <h4>Unificação de cadastros</h4>
    <b-form @submit.prevent="buscar">
      <div class="row mt-4">
        <div class="col-4"></div>
        <div class="col-4 text-left">
          <b-form-group label="Buscar Nome / CPF" class="w-100">
            <b-input
              type="text"
              v-model="filtro"
              placeholder="digite um nome ou cpf"
            />
          </b-form-group>
        </div>
        <div class="col-4 text-left pt-4">
          <b-btn variant="primary" type="submit" class="mt-2"
            ><b-icon-search /> Buscar</b-btn
          >
        </div>
      </div>
    </b-form>
    <div class="w-100 p-5 text-center" v-if="loading">
      <b-spinner /><br />
      aguarde...
    </div>
    <div class="row" v-if="!loading">
      <div
        :class="{
          'col-6': selecionados.length > 0,
          'col-12': selecionados.length <= 0,
        }"
      >
        <small
          ><i>
            *O primeiro registro selecionado será automaticamente o cadastro de
            origem para unificação.<br />
            *Esta ação não pode ser desfeita
          </i></small
        >
        <b-table
          hover
          striped
          small
          ref="selectableTable"
          id="selectableTable"
          :items="resultados"
          :fields="[
            { key: 'select', label: '' },
            { key: 'id', label: 'Código' },
            { key: 'razao_social', label: 'Nome' },
            { key: 'cpf', label: 'CPF' },
            { key: 'tel', label: 'Telefone' },
          ]"
          selectable
          @row-selected="onRowSelected"
        >
          <template #cell(razao_social)="row">
            <div class="text-left">
              {{ row.item.razao_social }}
            </div>
          </template>

          <template #cell(select)="row">
            <b-checkbox v-model="selecionados" :value="row.item" />
          </template>

          <template #cell(tel)="row">
            <div class="text-center">
              <span v-if="row.item.telefones && row.item.telefones.length > 0">
                {{ row.item.telefones[0].telefone }}
              </span>
            </div>
          </template>
        </b-table>
      </div>
      <div class="col-6" v-if="selecionados.length > 0">
        <h5>Selecionados</h5>
        <hr />
        <div class="m-4 card">
          <div class="card-body">
            <h5>Paciente Principal</h5>
            <div class="text-left">
              <b>Nome: </b> {{ pacientePrincipal.razao_social }}<br />
              <b>CPF: </b> {{ pacientePrincipal.cpf }}<br />
              <b>Telefone(s): </b>
              <ul>
                <li
                  v-for="tel in pacientePrincipal.telefones"
                  :key="`telef_pr_${tel.telefone}_${Math.random(2323232)}`"
                >
                  {{ tel.telefone }}
                </li>
              </ul>
              <br />
            </div>
          </div>
        </div>
        <b>Unificando os seguintes registros</b>
        <b-table
          :items="selecionados"
          small
          striped
          :fields="[
            { key: 'select', label: '' },
            { key: 'id', label: 'Código' },
            { key: 'razao_social', label: 'Nome' },
            { key: 'cpf', label: 'CPF' },
            { key: 'tel', label: 'Telefone' },
          ]"
        >
        </b-table>

        <b-btn block variant="success" @click="unificar" :disabled="unificando">
          <b-spinner small v-if="unificando"/>
          <b-icon-journal-arrow-down v-if="!unificando" /> Unificar
        </b-btn>
      </div>
    </div>
  </div>
</template>

<script>
import EntidadesLib from "../../libs/EntidadesLib";
export default {
  components: {},
  props: {},
  mounted() {},
  data() {
    return {
      filtro: "",
      resultados: [],
      loading: false,
      selecionados: [],
      pacientePrincipal: null,
      unificando: false,
    };
  },
  watch: {},
  computed: {},
  methods: {
    async buscar() {
      this.unificando = false;
      if (!this.filtro || this.filtro == "") {
        this.$swal("Atenção", "Informe uma busca!", "error");
        return;
      }

      this.loading = true;
      this.selecionados = [];
      this.resultados = await EntidadesLib.buscaPorNome(this.filtro, [
        "paciente",
      ]);
      this.loading = false;
    },
    onRowSelected(row) {
      if (!row || row.length<=0) return;
      let id = row[0].id;
      if (this.selecionados.some((x) => x.id == id)) {
        this.selecionados = this.selecionados.filter((x) => x.id != id);
      } else {
        this.selecionados.push(this.resultados.find((x) => x.id == id));
      }
      this.pacientePrincipal = null;
      if (this.selecionados.length > 0) {
        this.pacientePrincipal = this.selecionados[0];
      }
      this.$refs.selectableTable.clearSelected();
    },
    async unificar() {
      if (this.selecionados.length <= 0) {
        this.$swal(
          "Atenção",
          "Selecione ao menos 1 registro para continuar!",
          "error"
        );
        return;
      }

      this.unificando = true;

      let res = await EntidadesLib.unificarCPF(
        null,
        this.pacientePrincipal.id,
        this.filtro
      );

      console.log('res', res);
      if (res && res >0) {
        this.$swal({
          title: "Concluído!",
          icon: "success",
          showConfirmButton: false,
          timer: 2000,
          toast: true,
          position: 'top-end'
        })
        this.buscar();
      } else {
        this.$swal("Nenhuma ocorrencia!");
      }
      this.unificando = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
